import React from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    console.log("Message submitted: " + JSON.stringify(data));
    const element = document.createElement("a");
    const file = new Blob([data], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      {/*<form className="contact_form" onSubmit={handleSubmit(onSubmit)}>*/}
      {/*  <div className="first_row">*/}
      {/*    <input*/}
      {/*      {...register("name", { required: true })}*/}
      {/*      type="text"*/}
      {/*      placeholder="Name *"*/}
      {/*    />*/}
      {/*    {errors.name && errors.name.type === "required" && (*/}
      {/*      <span className="invalid-feedback">Name is required</span>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  /!* End .first_row *!/*/}

      {/*  <div className="second">*/}
      {/*    <input*/}
      {/*      {...register(*/}
      {/*        "email",*/}
      {/*        {*/}
      {/*          required: "Email is Required",*/}
      {/*          pattern: {*/}
      {/*            value: /\S+@\S+\.\S+/,*/}
      {/*            message: "Entered value does not match email format",*/}
      {/*          },*/}
      {/*        },*/}
      {/*        { required: true }*/}
      {/*      )}*/}
      {/*      type="email"*/}
      {/*      placeholder="Email *"*/}
      {/*    />*/}
      {/*    {errors.email && (*/}
      {/*      <span className="invalid-feedback">{errors.email.message}</span>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  /!* End .second *!/*/}

      {/*  <div className="third">*/}
      {/*    <textarea*/}
      {/*      {...register("message", { required: true })}*/}
      {/*      placeholder="Message *"*/}
      {/*    ></textarea>*/}
      {/*    {errors.message && (*/}
      {/*      <span className="invalid-feedback">Message is required.</span>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  /!* End .third *!/*/}

      {/*  <div className="edina_tm_button">*/}
      {/*    <button type="submit" className="color">*/}
      {/*      Submit*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*  /!* End tokyo_tm_button *!/*/}
      {/*</form>*/}
      {/* End contact */}

    </>
  );
};

export default Contact;
